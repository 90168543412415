import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Ionic4DatepickerModalComponent } from '@logisticinfotech/ionic4-datepicker';
import { ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { Observable } from 'rxjs';
import { ReportService } from 'src/app/services/api/report.service';
import { TransactionService } from 'src/app/services/api/transaction.service';

@Component({
  selector: 'app-transaction-total',
  templateUrl: './transaction-total.component.html',
  styleUrls: ['./transaction-total.component.scss'],
})
export class TransactionTotalComponent implements OnInit, OnChanges {
  trxns: any[];
  totalProfit: number = 0;
  types = [
    {
      type: '999'
    },
    {
      type: '916'
    }
  ];
  prices = [
    {
      buyPrice: 0,
      sellPrice: 0,
      updatedDate: new Date(),
      type: '999'
    },
    {
      buyPrice: 0,
      sellPrice: 0,
      updatedDate: new Date(),
      type: '916'
    }
  ];
  slideOption = {
    slidesPerView: 'auto',
    grabCursor: true,
    centerInsufficientSlides: true,
  };
  @Input() from: string;
  constructor(
    private reportService: ReportService,
    private trxnService: TransactionService
  ) {
    
  }

  ngOnInit() {
    
  }

  ngOnChanges() {
    const model = {
      from: this.from,
      to: this.from
    }
    this.reportService.products(model).subscribe(p => {
      this.prices = p;
      this.loadData();
    });
  }

  loadData() {
    this.totalProfit = 0;
    const model = {
      from: this.from,
      to: this.from
    }
    this.reportService.getTrxnTotals(model).subscribe(res => {
      this.trxns = res;
      this.trxns.forEach(trxn => {
        if(trxn.transactionType === 'Profit') {
          const priceRate = this.prices.find(pr => pr.type === trxn.currencyCode);
          const price = trxn.amount * priceRate.sellPrice;
          this.totalProfit += price;
        }
      })
    });
  }

}
