import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/api/admin.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-payment-summary',
  templateUrl: './payment-summary.component.html',
  styleUrls: ['./payment-summary.component.scss'],
})
export class PaymentSummaryComponent implements OnInit {

  public data = {
    totalSuccess: 0,
    totalPending: 0,
    totalFail: 0,
    totalCancel: 0
  };
  constructor(private adminService: AdminService,
              private toastCtrl: ToastService) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.adminService.getPaymentSummary().subscribe(res => {
      this.data = res;
    }, (error) => {
        this.toastCtrl.error(error);
    });
  }
}
