import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  constructor(private apiService: ApiService) { }
  city = ['Johor Bahru', 'Alor Setar', 'Kota Bharu', 'Malacca City', 'Seremban', 'Kuantan', 'George Town', 'Ipoh', 'Kangar', 'Kota Kinabalu', 'Kuching', 'Shah Alam', 'Kuala Terengganu', 'Kuala Lumpur', 'Putrajaya'];
  state = ['Johor', 'Kedah', 'Kelantan', 'Melaka', 'Negeri Sembilan', 'Pahang', 'Pulau Pinang', 'Perak', 'Perlis', 'Sabah', 'Sarawak', 'Selangor', 'Terengganu', 'WP Kuala Lumpur', 'WP Labuan', 'WP Putrajaya'];
  
  getGlobal(cate: string) {
    return this.apiService.get('/global/get/' + cate);
  }

  getAllState = (): Array<any> => {
    return this.state;
  }

  getAllCity = (): Array<any> => {
    return this.city;
  }

  getMarkup() {
    return this.apiService.get('/setting/markup');
  }

  updateMarkup(model: any) {
    return this.apiService.post('/setting/markup', model);
  }

  getCommission() {
    return this.apiService.get('/setting/commission');
  }

  updateCommission(model: any) {
    return this.apiService.post('/setting/commission', model);
  }

  getData(type: any) {
    return this.apiService.get('/global/crm/data/' + type);
  }

  ping() {
    return this.apiService.get('/global/ping');
  }

  goldChart(type: any) {
    return this.apiService.get('/global/gold/chart/' + type);
  }
}
