import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  constructor(private apiService: ApiService) { }

  getUserKycWaitingList() {
    return this.apiService.get('/admin/kyc/list/waiting');
  }
  verifyKyc(id: any, model: any) {
      return this.apiService.put('/admin/kyc/list/waiting/' + id, model);
  }
  getUserKycDetails(userId: any) {
    return this.apiService.get('/admin/kyc/details/' + userId);
  }
  verifyUserKyc(model: any) {
    return this.apiService.post('/admin/kyc/verify/user', model);
  }
  getUserInfo(userIC: any) {
    return this.apiService.get('/admin/userInfo/' + userIC);
  }
  getUser(userId: any) {
    return this.apiService.get('/admin/user/' + userId);
  }
  createTenant(model: any) {
    return this.apiService.post('/admin/tenant/create', model);
  }

  getUserList(keyword: any) {
    return this.apiService.get('/admin/user/list?keyword=' + keyword);
  }

  getUserDetails(userId: any) {
    return this.apiService.get('/admin/user/detail/' + userId);
  }

  createUser(model: any) {
    return this.apiService.post('/admin/user/create', model);
  }
  updateTenant(tenantId: any, model: any) {
    return this.apiService.post('/admin/tenant/update/' + tenantId, model);
  }
  getTenantByType(type: string) {
    return this.apiService.get('/admin/tenant/getbytype/' + type);
  }
  getTenantById(id: string) {
    return this.apiService.get('/admin/tenant/get/' + id);
  }
  getAllTenants() {
    return this.apiService.get('/admin/tenant/getalltenants');
  }

  getRoles() {
    return this.apiService.get('/admin/role/list');
  }

  changeUserStatus(userId: any, status: any) {
    return this.apiService.post('/admin/user/change/status/' + userId + '/' + status);
  }
  promoteUser(userId: any, tenantId: any) {
    return this.apiService.post('/admin/promote/user/' + userId + '/' + tenantId);
  }
  demoteUser(userId: any) {
    return this.apiService.post('/admin/demote/user/' + userId);
  }
  getChildTenant(tenantId: string) {
    return this.apiService.get('/admin/tenant/getchildTenant/' + tenantId);
  }
  getStaffList(keyword: any, target: any) {
    return this.apiService.get('/admin/staff/list?keyword=' + keyword + '&type=' + target);
  }

  updateUserInfo(id: any, model: any) {
    return this.apiService.post('/admin/user/update/' + id, model);
  }
  getMyChild() {
    return this.apiService.get('/admin/tenant/getmychild');
  }
  getPromotions() {
    return this.apiService.get('/admin/promotion/list');
  }
  savePromotion(model: any) {
    return this.apiService.post('/admin/promotion/save', model);
  }
  getPromotionDetail(id: any) {
    return this.apiService.get('/admin/promotion/detail/' + id);
  }
  deletePromotion(id: any) {
    return this.apiService.post('/admin/promotion/delete/' + id);
  }

  getLatestKycPending() {
    return this.apiService.get('/admin/kyc/recent/list');
  }
  getLatestPayment() {
    return this.apiService.get('/admin/payment/recent/list');
  }
  getUserSummary(model: any) {
    return this.apiService.post('/admin/user/summary', model);
  }
  getPaymentSummary() {
    return this.apiService.get('/admin/payment/summary');
  }
  getPaymentDetail(id: any) {
    return this.apiService.get('/admin/payment/detail/' + id);
  }
  searchPayment(keyword: any) {
    return this.apiService.get('/admin/payment/search?keyword=' + keyword);
  }
  withdrawManual(model: any) {
    return this.apiService.post('/admin/withdraw/digital', model);
  }
  updateGoldPrice(model: any) {
    return this.apiService.post('/setting/goldprice', model);
  }
}
