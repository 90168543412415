import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { User1Service } from 'src/app/services/api/user1.service';

@Component({
  selector: 'app-payout-request',
  templateUrl: './payout-request.component.html',
  styleUrls: ['./payout-request.component.scss'],
})
export class PayoutRequestComponent implements OnInit {

  askBalance = 0;
  userBanks = [];
  data = {
    amount: '',
    userBankId: ''
  };
  isAmountValid = true;
  isUserBankValid = true;
  selectedUserBank: any;
  constructor(private userService: User1Service,
              public modalCtrl: ModalController
              ) { }

  ngOnInit() {
    this.getAskBalance();
    this.getUserBanks();
  }

  getAskBalance() {
    this.userService.getAskSeryBalance().subscribe(ask => {
      this.askBalance = ask;
    }, (err) => {
    });
  }

  getUserBanks() {
    this.userService.getUserBanks().subscribe(res => {
      this.userBanks = res;
    }, (err) => {
    });
  }

  onSubmitClick() {
    if (this.validate()) {
      this.modalCtrl.dismiss(this.data);
    }
  }

  onSelectChange(selectedValue) {

  }

  onBackFunc() {
    this.modalCtrl.dismiss();
  }

  validate(): boolean {
    this.isAmountValid = true;
    this.isUserBankValid = true;
    if (!this.data.userBankId || this.data.userBankId.length === 0) {
      this.isUserBankValid = false;
    }
    if (!this.data.amount || Number(this.data.amount) < 10 || Number(this.data.amount) > 25000) {
      this.isAmountValid = false;
    }
    return this.isAmountValid && this.isUserBankValid;
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  formataNumero(e: any, separador: string = '.', decimais: number = 2) {
    const a = e.target.value.split('');
    let ns = '';
    a.forEach((c) => { if (!isNaN(c)) { ns = ns + c; } });
    // tslint:disable-next-line:radix
    ns = parseInt(ns).toString();
    if (ns.length < (decimais + 1)) { ns = ('0'.repeat(decimais + 1) + ns); ns = ns.slice((decimais + 1) * -1); }
    const ans = ns.split('');
    let r = '';
    for (let i = 0; i < ans.length; i++) {
      if (i === ans.length - decimais) {
        r = r + separador + ans[i];
      } else {
        r = r + ans[i];
      }
    }
    e.target.value = r;
  }
}
