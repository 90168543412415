import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root'
  })
export class DeeplinkService {
 constructor() {}
 deeplink() {
   const ua = navigator.userAgent.toLowerCase();
   const isAndroid = ua.indexOf('android') > -1; // android check
   const isIphone = ua.indexOf('iphone') > -1; // ios check
   if (isIphone === true) {
    const app = {
      launchApp() {
       setTimeout(() => {
         window.location.href = 'https://itunes.apple.com/us/app/appname/appid';
       }, 25);
       window.location.href = 'bundlename://linkname'; //which page to open(now from mobile, check its authorization)
      },
      openWebApp() {
       window.location.href = 'https://itunes.apple.com/us/app/appname/appid';
      }
    };
    app.launchApp();
    } else if (isAndroid === true) {
        const app = {
        launchApp() {
            window.location.replace('bundlename://linkname'); //which page to open(now from mobile, check its authorization)
            setTimeout(this.openWebApp, 500);
        },
        openWebApp() {
            window.location.href =  'https://play.google.com/store/apps/details?id=com.diya.sery';
        }
    };
        app.launchApp();
    } else {
    //navigate to website url
    }
 }
}
