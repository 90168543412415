import { Component, OnInit, ViewChildren, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';


import { AlertController, IonRouterOutlet, MenuController, Platform } from '@ionic/angular';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AngularFireMessaging } from '@angular/fire/messaging';
// import { FCM } from '@ionic-native/fcm/ngx';
import { StorageService } from './services/storage.service';
import { AuthService } from './services/api/auth.service';
import { environment } from 'src/environments/environment';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { User1Service } from './services/api/user1.service';
import { TransactionService } from './services/api/transaction.service';
import { ToastService } from './services/toast.service';
import { DeeplinkService } from './services/deeplink.service';
import { GlobalService } from './services/api/global.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  public selectedIndex = 0;
  FCM_KEY = 'FCM';
  @ViewChildren(IonRouterOutlet) routerOutlets: any;
  constructor(
    private menu: MenuController,
    private platform: Platform,
    private router: Router,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authService: AuthService,
    private afMessaging: AngularFireMessaging,
    public alertCtrl: AlertController,
    private toastCtrl: ToastService,
    private fcm: FCM,
    private userService: User1Service,
    private deeplinkService: DeeplinkService,
    private transactionService: TransactionService,
    private globalService: GlobalService
  ) {
    this.initializeApp();
    this.backButtonEvent();
  }
  ngOnInit() {
    if (environment.production) {
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
    }
  }


  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      if (this.platform.is('android') || this.platform.is('ios')) {
        this.fcm.requestPushPermission().then(() => {
          // ionic push notification example
          this.fcm.getToken().then(token => {
            console.log(token);
            console.log('Received Token');
            this.authService.setFcmToken(token);
          });
          // ionic push notification example
          this.fcm.onNotification().subscribe(data => {
            console.log(data);
            if (data.wasTapped) {
              console.log('Received in background');
              this.doNotification(data);
            } else {
              console.log('Received in foreground');
              this.doNotification(data);
            }
          });
          // refresh the FCM token
          this.fcm.onTokenRefresh().subscribe(token => {
            console.log(token);
            console.log('Received Refresh Token');
            this.authService.setFcmToken(token);
            const currentUser = this.authService.currentUserValue;
            if (currentUser) {
              this.userService.registerFcm(token).subscribe(res1 => {
              });
            }
          });
        });
      } else {
        this.deeplinkService.deeplink();
        this.requestPermission();
      }
    });
  }

  requestPermission() {
    this.afMessaging.requestToken
      .subscribe(
      (token) => {
        console.log(token);
        this.authService.setFcmToken(token);
        this.initFCMforPWA();
      },
      (error) => { console.error(error); }
    );
  }

  initFCMforPWA() {
    navigator.serviceWorker.getRegistration().then((registration) => {
        this.afMessaging.messaging.subscribe(
          (messaging) => {
            // we use this trick here to access useServiceWorker method:
            messaging.getToken().then((token) => {
              console.log('Token =>' + token);
            });
            messaging.onMessage((message) => {
              console.log(message);
              this.doNotification(message.data);
            });
          },
          (error) => { console.log('failed to subscribe to firebase messaging'); });
      });
  }

  doNotification(data: any) {
    if (data.type === 'Withdrawal') {
      this.alertCtrl.create({
        header: data.title,
        message: data.body,
        backdropDismiss: false,
        buttons: [
          {
            text: 'Reject',
            handler: () => {
              this.confirmToWithdraw(data, 'Rejected');
            }
          },
          {
            text: 'Approve',
            handler: () => {
              this.confirmToWithdraw(data, 'Paid');
            }
          }
        ]
      })
      .then(alert => {
        alert.present();
      });
    } else if (data.type === 'WithdrawalResponse') {
      this.alertCtrl.create({
        header: data.title,
        message: data.body,
        backdropDismiss: false,
        buttons: [
          {
            text: 'OK',
            role: 'Cancel',
            handler: () => {
              this.userService.markReadNotification(data.id).subscribe(res => {
                this.router.navigateByUrl('/transaction/withdraw-receipt/' + data.process_id);
              });
            }
          }
        ]
      })
      .then(alert => {
        alert.present();
      });
    } else if (data.type === 'LOGOUT') {
      this.alertCtrl.create({
        header: data.title,
        message: data.body,
        backdropDismiss: false,
        buttons: [
          {
            text: 'OK',
            role: 'Cancel',
            handler: () => {
              this.authService.logout();
            }
          }
        ]
      })
      .then(alert => {
        alert.present();
      });
    } else if (data.type === 'RemindKYC') {
      this.alertCtrl.create({
        header: data.title,
        message: data.body,
        backdropDismiss: false,
        buttons: [
          {
            text: 'OK',
            role: 'Cancel',
            handler: () => {
              this.userService.markReadNotification(data.id).subscribe(res => {
              });
            }
          }
        ]
      })
      .then(alert => {
        alert.present();
      });
    } else {
      this.alertCtrl.create({
        header: data.title,
        message: data.body,
        backdropDismiss: false,
        buttons: [
          {
            text: 'OK',
            role: 'Cancel',
            handler: () => {
            }
          }
        ]
      })
      .then(alert => {
        alert.present();
      });
    }
  }

  confirmToWithdraw(data: any, stt: string) {
    const model = {
      status: stt,
      type: data.type,
      transactionId: data.process_id,
      notificationId: data.id
    };
    this.transactionService.confirmToWithdraw(model).subscribe(
        (res) => {

        },
        (err) => {
          this.toastCtrl.error(err);
        }
    );
  }

  backButtonEvent() {
    this.platform.backButton.subscribe(() => {
        this.routerOutlets.forEach((outlet: IonRouterOutlet) => {
            if (this.router.url === '/home') {
              this.alertCtrl.create({
                header: 'Exit Diya App',
                message: 'Do you want to exit the app?',
                backdropDismiss: false,
                buttons: [
                  {
                    text: 'Cancel',
                    role: 'Cancel',
                  },
                  {
                    text: 'Yes',
                    handler: () => {
                      navigator['app'].exitApp();
                    }
                  }
                ]
              })
              .then(alert => {
                alert.present();
              });
            } else {
                window.history.back();
            }
        });
    });
  }
}
