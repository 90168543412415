import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { GlobalService } from 'src/app/services/api/global.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-address-detail',
  templateUrl: './address-detail.component.html',
  styleUrls: ['./address-detail.component.scss'],
})
export class AddressDetailComponent implements OnInit {
  isFullnameValid = true;
  isAddressValid = true;
  isPostalCodeValid = true;
  isStateValid = true;
  isCityValid = true;
  isMobileValid = true;
  personal = {
    address1: '',
    address2: '',
    fullname: '',
    mobile: '',
    state: '',
    city: '',
    postalCode: ''
  };
  state: any;
  constructor(
    public modalCtrl: ModalController,
    private global: GlobalService,
    private toastCtrl: ToastService,
    private navParams: NavParams) {
      this.personal = this.navParams.data.shippingDetail;
    }

  ngOnInit() {
    this.getData();
  }

  validate(): boolean {
    this.isFullnameValid = true;
    this.isAddressValid = true;
    this.isPostalCodeValid = true;
    this.isStateValid = true;
    this.isCityValid = true;
    this.isMobileValid = true;
    if (!this.personal.fullname || this.personal.fullname.length === 0) {
      this.isFullnameValid = false;
    }
    if (!this.personal.address1 || this.personal.address1.length === 0) {
      this.isAddressValid = false;
    }
    if (!this.personal.state || this.personal.state.length === 0) {
      this.isStateValid = false;
    }
    if (!this.personal.city || this.personal.city.length === 0) {
      this.isCityValid = false;
    }
    if (!this.personal.postalCode || this.personal.postalCode.length === 0) {
      this.isPostalCodeValid = false;
    }
    if (!this.personal.mobile || this.personal.mobile.length === 0) {
      this.isMobileValid = false;
    }
    return this.isFullnameValid && this.isAddressValid &&
    this.isPostalCodeValid && this.isStateValid && this.isCityValid && this.isMobileValid;
  }

  onSubmit() {
    if (this.validate()) {
      this.modalCtrl.dismiss(this.personal);
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  getData() {
    this.global.getData('state').subscribe(res => {
      this.state = res;
    }, (err) => {
        this.toastCtrl.error(err);
    });
  }

}
