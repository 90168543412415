import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {

  @Input() type: any;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onConfirm = new EventEmitter();
  title = '';
  public showPassword = false;
  private passwordPolicy = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&]).{8,}$/;
  item = {
    password : '',
    currentPassword: '',
    confirmPassword: ''
  };
  isPasswordValid = true;
  constructor() { }

  ngOnInit() {
    this.title = '';
  }

  public onPasswordToggle(): void {
    this.showPassword = !this.showPassword;
  }

  validate() {
    this.isPasswordValid = this.passwordPolicy.test(this.item.password);

    return this.isPasswordValid;
  }

  onSubmit() {
    if (this.validate()) {
      this.onConfirm.emit(this.item);
    }
  }
}
