import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';

//******** Angularfire ********/
import { AngularFireModule } from '@angular/fire';

//******* firebase api key ********//
import { environment } from '../environments/environment';

//******* firebase api key ********//
import { AngularFireMessagingModule } from '@angular/fire/messaging';

//******** UI_components / modal detail *********/
import { ModalContentPageModule } from './ui-components/modal-content/modal-content.module';

// //******** ionic4 rating *********/
import { IonicRatingModule } from 'ionic4-rating/dist';


// //******** Shared module *********/
import { SharedModule} from './shared/shared.module';



import { AppRoutingModule } from './app-routing.module';
import { HttpConfigInterceptor } from './services/httpConfig.interceptor';
import { NgxDatatableModule } from '@swimlane/ngx-datatable/lib/ngx-datatable.module';
import { DatePipe } from '@angular/common';
import { PipesModule } from './pipes/pipes.module';
import { ServiceWorkerModule } from '@angular/service-worker';
// import { FCM } from '@ionic-native/fcm/ngx';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { Ionic4DatepickerModule } from '@logisticinfotech/ionic4-datepicker';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { ClipboardModule, ClipboardService } from 'ngx-clipboard';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Market } from '@ionic-native/market/ngx';
import { ThemeService } from 'ng2-charts';
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicRatingModule, // Put ionic-rating module here
    SharedModule,
    HttpClientModule,
    IonicModule.forRoot({
      rippleEffect: false,
      mode: 'ios'
    }),
    IonicStorageModule.forRoot({
      name: '_ionicstorage',
      driverOrder: [ 'indexeddb', 'sqlite', 'websql']
    }),

    AppRoutingModule,

    //******* UI-components / modal ********//

    ModalContentPageModule,

    //******* UI-layout / map ********//
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    PipesModule,
    ServiceWorkerModule.register('combined-sw.js', {enabled: environment.production}),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    Ionic4DatepickerModule,
    ClipboardModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Clipboard,
    ClipboardService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    },
    FCM,
    InAppBrowser,
    Market,
    ThemeService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
