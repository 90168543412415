import { getLocaleDateFormat } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import {FormGroup } from '@angular/forms';
import { ignoreElements } from 'rxjs/operators';
import { AdminService } from 'src/app/services/api/admin.service';
import { AuthService } from 'src/app/services/api/auth.service';
import { GlobalService } from 'src/app/services/api/global.service';
import { User1Service } from 'src/app/services/api/user1.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-register-user-details',
  templateUrl: './register-user-details.component.html',
  styleUrls: ['./register-user-details.component.scss'],
})
export class RegisterUserDetailsComponent implements OnInit, OnChanges {
  form: FormGroup;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onNext = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onBack = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onCreate = new EventEmitter();
    // tslint:disable-next-line:no-output-on-prefix
  @Output() onSaveChange = new EventEmitter();
  @Input() type: string; // DETAIL/ ADMIN / SIGNUP / CREATE / UPDATE
  @Input() data: any;
  @Input() code: string;
  city: any;
  state: any;
  showIntroducer = true;
  showBranch = false;
  isShowBranchLvl = false;
  readonlyCheck = {
    citizenship: false,
    ic: false,
    password: false,
    fullname: false,
    email: false,
    address1 : false,
    address2 : false,
    postalCode : false,
    state : false,
    city: false,
    mobile: false,
    introducerId: false,
    status: false
  };
  personal = {
    citizenship: 'Malaysian',
    ic: '',
    password: '',
    fullname: '',
    email: '',
    address1 : '',
    address2 : '',
    postalCode : '',
    state : '',
    city: '',
    mobile: '',
    introducerId: '',
    status: '',
    tenantId: '',
    introducerName: '',
    isExistAsk: false,
    type: '',
    isOTC: false,
    isMarketing: false
  };
  hiddenCheck = {
    citizenship: true,
    ic: true,
    password: true,
    fullname: true,
    email: true,
    address1 : true,
    address2 : true,
    postalCode : true,
    state : true,
    city: true,
    mobile: true,
    introducerId: true,
    status: true
  };
  // tslint:disable-next-line:max-line-length
  private regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  isEmailValid = true;
  isNricValid = true;
  isPasswordValid = true;
  isFullnameValid = true;
  isAddressValid = true;
  isPostalCodeValid = true;
  isStateValid = true;
  isCityValid = true;
  isMobileValid = true;
  @Input() isStaff: boolean;
  public showPassword = false;
  public showMarketing = false;
  branches: any;
  private passwordPolicy = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&]).{8,}$/;
  private icPolicy = /^[A-Za-z0-9?]+$/;
  constructor(
    private global: GlobalService,
    private userService: User1Service,
    private toastCtrl: ToastService,
    private authService: AuthService,
    private adminService: AdminService
  ) {
    const user = this.authService.currentUserValue;
    if (user != null) {
      if (user.roles.includes('G$PPYN') || user.roles.includes('G$BRANCH') || user.roles.includes('G$STAFF')
         || user.roles.includes('G$ADMIN') || user.roles.includes('G$SUPPER_ADMIN') || user.roles.includes('G$SYSTEM_ADMIN')) {
          this.showIntroducer = false;
         }
      if (user.roles.includes('G$PPYN')) {
        this.personal.type = 'PPYN';
        this.showBranch = true;
        this.adminService.getMyChild().subscribe(res => {
          this.branches = res;
        });
      }

      if (user.roles.includes('G$ADMIN') || user.roles.includes('G$SUPPER_ADMIN')) {
        this.showMarketing = true;
      }
    }
    this.getState();
    this.isStaff = false;
    this.personal.introducerId = this.code;
   }

   public onPasswordToggle(): void {
    this.showPassword = !this.showPassword;
  }

  ngOnInit() {}

  onNextClick() {
    if (this.validate()) {
      this.userService.checkUser(this.personal.ic, this.personal.email).subscribe(res => {
        if (res) {
          this.personal.isExistAsk = true;
        }
        if ( this.personal.introducerId != null && this.personal.introducerId.length > 0) {
          this.userService.checkIntroducer(this.personal.introducerId).subscribe( res1 => {
            this.onNext.emit(this.personal);
          }, (err) => {
            this.toastCtrl.error(err);
          });
        } else {
          this.onNext.emit(this.personal);
        }
      }, (err) => {
        this.toastCtrl.error(err);
      });
    }
  }
  onBackClick() {
    this.onBack.emit();
  }

  ngOnChanges(changes: { [propKey: string]: any }) {
    if (changes.data != null) {
      this.data = changes.data.currentValue;
      if (this.data != null) {
        this.personal.fullname = this.data.profile.fullname;
        this.personal.ic = this.data.profile.ic;
        this.personal.email = this.data.email;
        this.personal.introducerId = this.data.introducerId;
        this.personal.citizenship = this.data.profile.citizenship;
        this.personal.mobile = this.data.profile.phoneNumber;
        this.personal.address1 = this.data.profile.address.address1;
        this.personal.address2 = this.data.profile.address.address2;
        this.personal.postalCode = this.data.profile.address.postalCode;
        this.personal.state = this.data.profile.address.state;
        this.personal.city = this.data.profile.address.city;
        this.personal.status = this.data.status;
        this.personal.tenantId = this.data.tenantId;
        if (this.personal.tenantId !== null && this.personal.tenantId !== '') {
          this.isStaff = true;
          this.showIntroducer = false;
          this.readonlyCheck.email = false;
        }
        if ((this.type === 'UPDATE') && (this.personal.introducerId === null || String(this.personal.introducerId).length === 0)
        && (this.personal.tenantId == null || this.personal.tenantId.length === 0)) {
          this.readonlyCheck.introducerId = false;
        }
      }
    }

    if (changes.type != null) {
      this.type = changes.type.currentValue;
      switch (this.type) {
        case 'ADMIN':
          this.readonlyCheck.address1 = true;
          this.readonlyCheck.address2 = true;
          this.readonlyCheck.citizenship = true;
          this.readonlyCheck.city = true;
          this.readonlyCheck.email = false;
          this.readonlyCheck.fullname = false;
          this.readonlyCheck.ic = true;
          this.readonlyCheck.introducerId = true;
          this.readonlyCheck.mobile = true;
          this.readonlyCheck.postalCode = true;
          this.readonlyCheck.state = true;
          break;
        case 'UPDATE':
          this.readonlyCheck.address1 = false;
          this.readonlyCheck.address2 = false;
          this.readonlyCheck.citizenship = true;
          this.readonlyCheck.city = false;
          this.readonlyCheck.email = true;
          this.readonlyCheck.fullname = true;
          this.readonlyCheck.ic = true;
          this.readonlyCheck.introducerId = true;
          this.readonlyCheck.mobile = false;
          this.readonlyCheck.postalCode = false;
          this.readonlyCheck.state = false;
          break;
        case 'CREATE':
          this.readonlyCheck.address1 = false;
          this.readonlyCheck.address2 = false;
          this.readonlyCheck.citizenship = false;
          this.readonlyCheck.city = false;
          this.readonlyCheck.email = false;
          this.readonlyCheck.fullname = false;
          this.readonlyCheck.ic = false;
          this.readonlyCheck.introducerId = false;
          this.readonlyCheck.mobile = false;
          this.readonlyCheck.postalCode = false;
          this.readonlyCheck.state = false;
          break;
        case 'VIEW':
          this.readonlyCheck.address1 = true;
          this.readonlyCheck.address2 = true;
          this.readonlyCheck.citizenship = true;
          this.readonlyCheck.city = true;
          this.readonlyCheck.email = true;
          this.readonlyCheck.fullname = true;
          this.readonlyCheck.ic = true;
          this.readonlyCheck.introducerId = true;
          this.readonlyCheck.mobile = true;
          this.readonlyCheck.postalCode = true;
          this.readonlyCheck.state = true;
          break;
      }
    }

    if (changes.code &&  String(changes.code.currentValue).length > 0) {
      this.personal.introducerId = changes.code.currentValue;
      this.readonlyCheck.introducerId = true;
    }
  }

  validate(): boolean {
    this.isEmailValid = true;
    this.isNricValid = true;
    this.isPasswordValid = true;
    this.isFullnameValid = true;
    this.isAddressValid = true;
    this.isPostalCodeValid = true;
    this.isStateValid = true;
    this.isCityValid = true;
    this.isMobileValid = true;
    this.isEmailValid = this.regex.test(this.personal.email);
    this.isNricValid = this.icPolicy.test(this.personal.ic);
    this.isPasswordValid = this.passwordPolicy.test(this.personal.password);
    if (!this.personal.fullname || this.personal.fullname.length === 0 || String(this.personal.fullname).length > 70) {
      this.isFullnameValid = false;
    }
    if (!this.personal.address1 || this.personal.address1.length === 0 || String(this.personal.address1).length > 70) {
      this.isAddressValid = false;
    }
    if (!this.personal.state || this.personal.state.length === 0) {
      this.isStateValid = false;
    }
    if (!this.personal.city || this.personal.city.length === 0 || String(this.personal.city).length > 70) {
      this.isCityValid = false;
    }
    if (!this.personal.postalCode || this.personal.postalCode.length === 0 || (String(this.personal.postalCode)).length > 5) {
      this.isPostalCodeValid = false;
    }
    if (!this.personal.mobile || this.personal.mobile.length === 0) {
      this.isMobileValid = false;
    }
    return this.isEmailValid && this.isNricValid && this.isPasswordValid
    && this.isFullnameValid && this.isAddressValid && this.isPostalCodeValid && this.isStateValid && this.isCityValid && this.isMobileValid;
  }

  onCreateFunc() {
    if (this.validate()) {
      this.onCreate.emit(this.personal);
    }
  }
  onSaveChangeClick() {
    this.onSaveChange.emit(this.personal);
  }

  checkIntroducer() {
    this.userService.checkIntroducer(this.personal.introducerId).subscribe(res => {
      this.personal.introducerName = res;
    }, (err) => {
        this.toastCtrl.error(err);
    });
  }

  getState() {
    this.global.getData('state').subscribe(res => {
      this.state = res;
    }, (err) => {
        this.toastCtrl.error(err);
    });
  }

  radioChecked(event) {
    if (event.detail.value === 'Branch') {
      this.isShowBranchLvl = true;
    } else {
      this.isShowBranchLvl = false;
    }
  }
}
