import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class PayoutService {
  constructor(private apiService: ApiService) { }

  getStatusList() {
    return this.apiService.get('/payout/status/list');
  }

  requestAskPayout(model: any) {
    return this.apiService.post('/payout/ask/request', model);
  }

  searchMyPayout(model: any) {
    return this.apiService.post('/payout/my/list', model);
  }

  searchRequestList(model: any) {
    return this.apiService.post('/payout/ask/request/list', model);
  }

  getMyPayoutDetail(id: any) {
    return this.apiService.get('/payout/mypayout/detail/' + id);
  }

  getPayoutDetail(id: any) {
    return this.apiService.get('/payout/detail/' + id);
  }

  changePayoutStatus(model: any) {
    return this.apiService.post('/payout/change/status', model);
  }

  dowloadEbankerFile(model: any) {
    return this.apiService.post('/payout/download/ebanker', model);
  }
}
