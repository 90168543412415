import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  constructor(private apiService: ApiService, private http: HttpClient) { }

  buyphysical(model: any): Observable<any> {
    return this.apiService.post('/transaction/buyphysical', model);
  }
  depositphysical(model: any): Observable<any> {
    return this.apiService.post('/transaction/depositphysical', model);
  }
  execute(type: string, model: any): Observable<any> {
    return this.apiService.httpPost('/transaction/execute/' + type, model);
  }
  get(trxnId: string): Observable<any> {
      return this.apiService.get('/transaction/get/' + trxnId);
  }
  getBalances(): Observable<any> {
    return this.apiService.get('/transaction/getbalance');
  }
  walletDetail(goldCode: string): Observable<any> {
    return this.apiService.get('/transaction/wallet/' + goldCode);
  }
  getLatestTrxn(): Observable<any> {
    return this.apiService.get('/transaction/getlatest/');
  }
  balance(goldCode: string): Observable<any> {
    return this.apiService.get('/transaction/balance/' + goldCode);
  }
  storageBalance(goldCode: string): Observable<any> {
    return this.apiService.get('/transaction/storagebalance/' + goldCode);
  }
  addBooking(model: any): Observable<any> {
    return this.apiService.post('/transaction/booking/add', model);
  }
  getBookings(goldCode: string): Observable<any> {
    return this.apiService.get('/transaction/booking/get/' + goldCode);
  }
  getUserBookings(userId: string): Observable<any> {
    return this.apiService.get('/transaction/booking/getbyuser/' + userId);
  }
  filterBookings(model: any): Observable<any> {
    return this.apiService.post('/transaction/booking/filter', model);
  }
  buyBooking(bookingId: string): Observable<any> {
    return this.apiService.post('/transaction/booking/buy/' + bookingId);
  }
  cancelBooking(bookingId: string): Observable<any> {
    return this.apiService.post('/transaction/booking/cancel/' + bookingId);
  }
  products(isStorage: boolean) {
    return this.apiService.get('/transaction/products/' + isStorage);
  }
  withdraw(model: any) {
    return this.apiService.post('/transaction/withdraw', model);
  }
  getCommissions(model: any) {
    return this.apiService.post('/transaction/commission', model);
  }
  payoutCommissions(model: any) {
    return this.apiService.post('/transaction/payout', model);
  }
  payoutIndividualCommissions(model: any) {
    return this.apiService.post('/transaction/payout/individual', model);
  }
  payoutHistory(model: any) {
    return this.apiService.post('/transaction/payout/history', model);
  }
  getIndividualCommission(model: any) {
    return this.apiService.post('/transaction/commission/individual', model);
  }
  payoutIndividualHistory(model: any) {
    return this.apiService.post('/transaction/payout/individual/history', model);
  }
  confirmToWithdraw(model: any) {
    return this.apiService.post('/transaction/withdraw/updateStatus', model);
  }
  getByItemAndUser(userId: string, itemId: string, trxnNumber: string) {
    return this.apiService.get(`/transaction/get/${userId}/${itemId}/${trxnNumber}`);
  }
  history(model: any) {
    return this.apiService.post('/transaction/history/filter', model);
  }
  refund(model: any) {
    return this.apiService.post('/transaction/refund', model);
  }
  getRefundedTransactions() {
    return this.apiService.get('/transaction/refundeds');
  }
  getRefundImages(trxnId: string) {
    return this.apiService.get(`/transaction/refund/images/${trxnId}`);
  }
  getUserRefunds() {
    return this.apiService.get('/transaction/user/refundeds');
  }
}
