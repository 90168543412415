import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'src/app/services/api/auth.service';
import { NavController } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService,
        private navController: NavController
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authService.currentUserValue;
        if (currentUser) {
            // authorised so return true
            if  (route.data.roles) {
                let isOk = false;
                const splitted = currentUser.roles.split(',') as any[];
                splitted.forEach(element => {
                    if (route.data.roles.indexOf(element) !== -1 ) {
                        isOk = true;
                    }
                });

                if (isOk) {
                    return true;
                } else {
                    this.router.navigate(['/']);
                    return false;
                }
            }
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.navController.navigateRoot(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const currentUser = this.authService.currentUserValue;
        if (currentUser) {
            // authorised so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.navController.navigateRoot(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}
