import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class WithdrawalService {
  constructor(private apiService: ApiService) { }

  getWithdrawalSetting() {
    return this.apiService.get('/withdrawal/setting');
  }

  updateWithdrawalSetting(model: any) {
    return this.apiService.post('/withdrawal/setting', model);
  }

  getStatusList() {
    return this.apiService.get('/withdrawal/status/list');
  }

  getDefaultData(code: any) {
    return this.apiService.get('/withdrawal/default/data/' + code);
  }

  searchMyList(model: any) {
    return this.apiService.post('/withdrawal/my/list', model);
  }

  searchRequestList(model: any) {
    return this.apiService.post('/withdrawal/request/list', model);
  }

  withdrawDigitalGoal(model: any) {
    return this.apiService.post('/withdrawal/gold/request', model);
  }

  updateStatus(model: any) {
    return this.apiService.post('/withdrawal/change/status', model);
  }

  confirmRecevie(model: any) {
    return this.apiService.post('/withdrawal/user/received', model);
  }

  getMyWithdrawalDetail(id: any) {
    return this.apiService.get('/withdrawal/mywithdrawal/detail/' + id);
  }

  getWithdrawalDetail(id: any) {
    return this.apiService.get('/withdrawal/detail/' + id);
  }

  getTransactionDetail(id: any) {
    return this.apiService.get('/withdrawal/transaction/detail/' + id);
  }
}
