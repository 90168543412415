import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GlobalService } from 'src/app/services/api/global.service';
import { Ionic4DatepickerModalComponent } from '@logisticinfotech/ionic4-datepicker';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-user-personal-detail',
  templateUrl: './user-personal-detail.component.html',
  styleUrls: ['./user-personal-detail.component.scss'],
})
export class UserPersonalDetailComponent implements OnInit {
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onBack = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onSaveChange = new EventEmitter();
  personal = {
    gender: 'L',
    citizenshipStatus: '',
    race: '',
    marital: '',
    occupation: '',
    occupationSector: '',
    religion: '',
    nationality: '',
    dob: '',
  };
  citizenships: any[];
  maritals: any[];
  races: any[];
  occupations: any[];
  datePickerObj: any = {};
  countries: any[];
  religions: any[];
  citizenshipCodes: any[];
  isGenderValid = true;
  isDoBValid = true;
  isCitizenshipStatusValid = true;
  isRaceValid = true;
  isMaritalStatusValid = true;
  isReligionValid = true;
  isNationalityValid = true;
  isOccupationValid = true;
  isOccupationSectorValid = true;
  today = new Date();
  constructor(private globalService: GlobalService, public modalCtrl: ModalController) { }

  ngOnInit() {
    this.getData();
    this.datePickerObj = {
      dateFormat: 'DD-MM-YYYY', fromDate: new Date(1900, 1, 1),
      toDate:  new Date(this.today.getFullYear() - 18, this.today.getMonth(), this.today.getDay())
    };
  }

  async openDatePicker() {
    const datePickerModal = await this.modalCtrl.create({
      component: Ionic4DatepickerModalComponent,
      cssClass: 'li-ionic4-datePicker',
      componentProps: {
         'objConfig': this.datePickerObj,
      }
    });
    await datePickerModal.present();

    datePickerModal.onDidDismiss()
      .then((data) => {
        console.log(data);
        this.personal.dob = data.data.date;
      });
  }

  getData() {
    this.globalService.getData('citizenship').subscribe(res => {
      this.citizenships = res;
    }, (err) => {
    });

    this.globalService.getData('marital').subscribe(res => {
      this.maritals = res;
    }, (err) => {
    });

    this.globalService.getData('race').subscribe(res => {
      this.races = res;
    }, (err) => {
    });

    this.globalService.getData('occupation').subscribe(res => {
      this.occupations = res;
    }, (err) => {
    });

    this.globalService.getData('nationality').subscribe(res => {
      this.countries = res;
    }, (err) => {
    });

    this.globalService.getData('religion').subscribe(res => {
      this.religions = res;
    }, (err) => {
    });

  }

  onBackFunc() {
    this.onBack.emit();
  }
  onSaveChangeClick() {
    if (this.validate()) {
      this.onSaveChange.emit(this.personal);
    }
  }

  validate(): boolean {
    this.isGenderValid = true;
    this.isDoBValid = true;
    this.isCitizenshipStatusValid = true;
    this.isRaceValid = true;
    this.isMaritalStatusValid = true;
    this.isReligionValid = true;
    this.isNationalityValid = true;
    this.isOccupationValid = true;
    this.isOccupationSectorValid = true;
    if (!this.personal.gender || this.personal.gender.length === 0) {
      this.isGenderValid = false;
    }
    if (!this.personal.dob || this.personal.dob.length === 0) {
      this.isDoBValid = false;
    }
    if (!this.personal.citizenshipStatus || this.personal.citizenshipStatus.length === 0) {
      this.isCitizenshipStatusValid = false;
    }

    if (!this.personal.race || this.personal.race.length === 0) {
      this.isRaceValid = false;
    }
    if (!this.personal.marital || this.personal.marital.length === 0) {
      this.isMaritalStatusValid = false;
    }
    if (!this.personal.religion || this.personal.religion.length === 0) {
      this.isReligionValid = false;
    }
    if (!this.personal.nationality || this.personal.nationality.length === 0) {
      this.isNationalityValid = false;
    }
    if (!this.personal.occupation || this.personal.occupation.length === 0  || String(this.personal.occupation).length > 30) {
      this.isOccupationValid = false;
    }
    if (!this.personal.occupationSector || this.personal.occupationSector.length === 0) {
      this.isOccupationSectorValid = false;
    }

    return this.isGenderValid && this.isDoBValid && this.isCitizenshipStatusValid && this.isRaceValid && this.isMaritalStatusValid
    && this.isMaritalStatusValid && this.isReligionValid && this.isNationalityValid
    && this.isOccupationValid && this.isOccupationSectorValid;
  }

}
