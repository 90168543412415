import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import { AuthService } from 'src/app/services/api/auth.service';
import { PayoutService } from 'src/app/services/api/payout.service';
import { User1Service } from 'src/app/services/api/user1.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-payout-detail',
  templateUrl: './payout-detail.component.html',
  styleUrls: ['./payout-detail.component.scss'],
})
export class PayoutDetailComponent implements OnInit {

  data = {
    amount: 0,
    status: '',
    createdOn: '',
    payoutTo: '',
    payoutFrom: '',
    transactionNo: '',
    remark: '',
    approvedOn: '',
    errorReason: '',
    ebankerId: '',
    id: '',
    generatedOn: '',
    completedOn: '',
    updatedOn: ''
  };
  model = {
    status: '',
    id: '',
    remark: '',
    errorReason: ''
  };
  id: any;
  constructor(
    private modalCtrl: ModalController,
    public alertCtrl: AlertController,
    private payoutService: PayoutService,
    private toastCtrl: ToastService,
    private navParams: NavParams,
    private authService: AuthService
  ) { }

  isAdmin = false;
  ngOnInit() {
    const user = this.authService.currentUserValue;
    if (user != null && (user.roles.includes('G$SUPPER_ADMIN') || user.roles.includes('G$ADMIN') || user.roles.includes('G$STAFF_HQ'))) {
      this.isAdmin = true;
    }
    this.id = this.navParams.data.id;
    if (this.isAdmin) {
      this.getDataByAdmin();
    } else {
      this.getData();
    }
  }

  getData() {
    this.payoutService.getMyPayoutDetail(this.id).subscribe(res => {
      this.data = res;
    },
    (err) => {
      this.toastCtrl.error(err);
    }
    );
  }

  getDataByAdmin() {
    this.payoutService.getPayoutDetail(this.id).subscribe(res => {
      this.data = res;
    },
    (err) => {
      this.toastCtrl.error(err);
    }
    );
  }

  async confirmToSubmit(status) {
    const alert = await this.alertCtrl.create({
      header: 'Confirmation',
      message: 'Are you sure you want to perform this transaction?',
      subHeader: '',
      cssClass: 'info-dialog',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
          }
        },
        {
          text: 'Ok',
          handler: () => {
            this.onSubmit(status);
          }
        }
      ]
    });
    await alert.present();
  }

  onSubmit(status) {
    this.model.id = this.id;
    this.model.status = status;
    this.model.errorReason = this.data.errorReason;
    this.model.remark = this.data.remark;
    this.modalCtrl.dismiss(this.model);
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
