import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  constructor(private apiService: ApiService, private http: HttpClient) { }

  getTrxnTotals(model: any) {
    return this.apiService.post('/report/totals', model);
  }

  getTopBranches(model: any) {
    return this.apiService.post(`/report/topbranches`, model);
  }

  products(model: any) {
    return this.apiService.post('/report/products', model);
  }
}
