import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AlertController, NavController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { UserModel } from 'src/app/shared/models/user.model';
import { StorageService } from '../storage.service';
import { User1Service } from './user1.service';
import { Market } from '@ionic-native/market/ngx';

const USER_KEY = 'currentUser';
const FCM_KEY = 'FCM';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentUserSubject: BehaviorSubject<UserModel>;
  private currentFcmToken: string;

  constructor(private http: HttpClient,
              private navController: NavController,
              private userService: User1Service,
              public alertCtrl: AlertController,
              private market: Market,
              private platform: Platform,
              public storageService: StorageService) {
    this.storageService.get(USER_KEY).then((user) => {
      this.currentUserSubject = new BehaviorSubject<UserModel>(JSON.parse(user));
    });
  }

  public get currentUserValue(): UserModel {
    if (this.currentUserSubject) {
      return this.currentUserSubject.getValue();
    }
    return null;
  }

  login(model: { username: string; password: string, token: string, platform: string }) {
    const credentials = `username=${model.username}&password=${model.password}&token=${model.token}&platform=${model.platform}`;
    const headers = new HttpHeaders().set(
        'Content-Type',
        'application/x-www-form-urlencoded'
    ).set('Access-Control-Allow-Origin', '*').set('Access-Control-Allow-Headers',
    'Authorization,DNT,X-Mx-ReqToken,Keep-Alive,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content Type,Orgin');
    return this.http.post(`${environment.apiEndpoint}/user/login`, credentials, {
        headers,
    });
  }
  logout() {
       // remove user from local storage to log user out
       // this.storageService.set(USER_KEY, '').then(() => {});
       this.storageService.clear();
       this.currentUserSubject.next(null);
       this.navController.navigateRoot(['/login']);
  }

  set(token: any) {
    if (token && token.access_token) {
        this.currentUserSubject.next(token);
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        this.storageService.set(USER_KEY, JSON.stringify(token)).then(() => {
        });
    }
  }

  public get fcmToken(): string {
    console.log('local token:' + this.currentFcmToken);
    return this.currentFcmToken;
  }

  setFcmToken(token: string) {
    this.currentFcmToken = token;
  }

  clear() {
    this.storageService.clear();
    this.currentUserSubject.next(null);
  }

  underMaintenance() {
    this.navController.navigateRoot(['/maintenance']);
  }

  async upgradeRequired() {
    let isBrowser = false;
    if (this.platform.is('desktop')  || this.platform.is('mobileweb')) {
      isBrowser = true;
    }
    const alert = await this.alertCtrl.create({
      header: 'Upgrade required',
      // tslint:disable-next-line:max-line-length
      message: isBrowser ? 'Oops. Looks like your browser is having some issues. Please press Ctrl+F5 to refresh your browser' : 'There is a newer version available for download. Please update the app by visiting your respective mobile stores for best app experience.',
      subHeader: '',
      cssClass: 'info-dialog',
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            if (isBrowser) {
              window.location.reload();
            } else {
              if (this.platform.is('android')) {
                this.market.open('com.diya.sery');
              } else if (this.platform.is('ios')) {
                this.market.open('id376771144');
              }
            }
          }
        }
      ]
    });
    await alert.present();
  }
}
