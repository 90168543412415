import { Component, Output, EventEmitter, OnInit, Input, OnChanges } from '@angular/core';
import { title } from 'process';

@Component({
  selector: 'app-register-confirm',
  templateUrl: './register-confirm.component.html',
  styleUrls: ['./register-confirm.component.scss'],
})
export class RegisterConfirmComponent implements OnInit, OnChanges {
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onConfirm = new EventEmitter();
  @Input() type: string;
  @Input() data: any;
  code = '';
  isValid = true;
  title = '';
  email = '';
  constructor() {
    // tslint:disable-next-line:max-line-length
    this.title = 'Registration Successful! An email has been sent to your registered email with a verification code. Please enter the verification code below to verify your account.';
    if (this.type === 'ForgotPassword') {
      // tslint:disable-next-line:max-line-length
      this.title = 'Forgot Password! An email has been sent to your registered email with a verification code. Please enter the verification code below to reset your password.';
    }
  }

  onConfirmFunc(): void {
    if (this.code.length === 6) {
      this.onConfirm.emit(this.code);
    } else {
      this.isValid = false;
    }
  }
  ngOnInit() {
    // tslint:disable-next-line:max-line-length
    this.title = '';
  }

  ngOnChanges(changes: { [propKey: string]: any }) {
    if (changes.type != null) {
      this.type = changes.type.currentValue;
      if (this.type === 'ForgotPassword') {
        // tslint:disable-next-line:max-line-length
        this.title = '';
      }
    }
    if (changes.data != null) {
      this.email = changes.data.currentValue;
    }
  }
}
