import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
  } from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { map, catchError, finalize, tap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LoadingController, Platform } from '@ionic/angular';
import { AuthService } from './api/auth.service';
import { environment } from '../../environments/environment';
@Injectable()
  export class HttpConfigInterceptor implements HttpInterceptor {
    isLoading = false;
    constructor(
        public loadingController: LoadingController,
        public authService: AuthService,
        private platform: Platform,
        ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const user = this.authService.currentUserValue;
        if (user) {
        request = request.clone({
            setHeaders: {
            Authorization: user.token_type + ' ' + user.access_token
            }
          });
        }

        if (!request.headers.has('Content-Type')) {
        request = request.clone({
            setHeaders: {
            'content-type': 'application/json'
            }
        });
        }

        request = request.clone({
        headers: request.headers.set('Accept', 'application/json')
        });

        request = request.clone({
          headers: request.headers.set('TimeZone-Offset', `${new Date().getTimezoneOffset()}`)
        });

        let platform = '';
        if (this.platform.is('desktop') || this.platform.is('mobileweb')) {
          platform = 'Web';
        } else {
          if (this.platform.is('android')) {
            platform = 'Android';
          } else if (this.platform.is('ios')) {
            platform = 'Ios';
          }
        }
        request = request.clone({
          headers: request.headers.set('Platform', platform)
        });
        request = request.clone({
          headers: request.headers.set('Version', `${environment.version}`)
        });
        // this.showLoader();
        // return next.handle(request).pipe(
        // map((event: HttpEvent<any>) => {
        //     if (event instanceof HttpResponse) {
        //     console.log('event--->>>', event);
        //     }
        //     this.hideLoader();
        //     return event;
        // }),
        // catchError((error: HttpErrorResponse) => {
        //     // console.error(error);
        //     if (error.status === 401) {
        //       // auto logout if 401 response returned from api
        //       this.authService.logout();
        //       location.reload(true);
        //     }
        //     this.hideLoader();
        //     return throwError(error);
        // }));
        if (request.url.indexOf('http') !== 0) {
          return next.handle(request);
        }
        return from(this.loadingController.create())
          .pipe(
            tap((loading) => {
              return loading.present();
            }),
            switchMap((loading) => {
              return next.handle(request).pipe(
                 map((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                    //console.log('event--->>>', event);
                    }
                    return event;
                }),
                catchError((error: HttpErrorResponse) => {
                      // console.error(error);
                      if (error.status === 401) {
                        // auto logout if 401 response returned from api
                        this.authService.logout();
                        // location.reload(true);
                      } else if (error.status === 503) {
                        // under maintenance
                        this.authService.underMaintenance();
                      } else if (error.status === 426) {
                        // under maintenance
                        this.authService.upgradeRequired();
                      }
                      return throwError(error);
                }),
                finalize(() => {
                  loading.dismiss();
                }),
              );
            })
          );

    }

    async showLoader() {
        this.isLoading = true;
        return await this.loadingController.create({
              }).then(a => {
                a.present().then(() => {
                  console.log('presented');
                  if (!this.isLoading) {
                    a.dismiss().then(() => console.log('abort presenting'));
                  }
                });
              });
    }
    async hideLoader() {
        this.isLoading = false;
        // return await this.loadingController.dismiss().then(() => console.log('dismissed'));
      }
  }
