import { ToastController } from '@ionic/angular';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ToastService {

  constructor(public toastController: ToastController) { }

  async presentToast(message: string) {
    const toast = await this
      .toastController
      .create({ 'position': 'bottom', 'duration': 2000, 'message': message });
    toast.present();
  }

  async error(message: string) {
    const toast = await this
      .toastController
      .create({
        'position': 'top',
        'message': message,
        'color': 'danger',
        'header': 'Sorry, we can NOT proceed with your request. Please check the following:',
        'duration': 5000,
        buttons: [
          {
            text: 'OK',
            role: 'cancel',
            handler: () => {
              console.log('Cancel clicked');
            }
          }
        ]
      });
    toast.present();
  }

  async success(message: string) {
    const toast = await this
      .toastController
      .create({
        'position': 'top',
        'message': message,
        'color': 'success',
        'header': 'Success Information',
        'duration': 2000
      });
    toast.present();
  }
}
