import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AdminService } from 'src/app/services/api/admin.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-user-summary',
  templateUrl: './user-summary.component.html',
  styleUrls: ['./user-summary.component.scss'],
})
export class UserSummaryComponent implements OnInit, OnChanges {
  @Input() filter: any;
  public data = {
    totalUser: 0,
    totalMemberToday: 0,
    totalMember: 0,
    totalStaff: 0
  };
  constructor(private adminService: AdminService,
              private toastCtrl: ToastService) { }

  ngOnChanges() {
    this.loadData();
  }

  ngOnInit() {
    //this.loadData();
  }

  loadData() {
    this.adminService.getUserSummary(this.filter).subscribe(res => {
      this.data = res;
    }, (error) => {
        this.toastCtrl.error(error);
    });
  }

}
