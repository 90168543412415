import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
})
export class SupportComponent implements OnInit {

  constructor(private modalCtrl: ModalController,
              private iab: InAppBrowser) { }

  ngOnInit() {}

  close() {
    this.modalCtrl.dismiss();
  }

  openFAQ() {
    const browser = this.iab.create('http://www.digitalyapeim.com/faq.html', '_system');
    browser.show();
  }

  mailTo(mail) {
    window.location.href = 'mailto:' + mail;
  }
}
