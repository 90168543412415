import { Component, EventEmitter, OnChanges, OnInit, Output } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { GlobalService } from 'src/app/services/api/global.service';

@Component({
  selector: 'app-bank-detail',
  templateUrl: './bank-detail.component.html',
  styleUrls: ['./bank-detail.component.scss'],
})
export class BankDetailComponent implements OnInit, OnChanges {
  banks = [];
  data = {
    accountNumber: '',
    bankName: '',
    shortName: '',
    fullname: '',
    bankId: '',
    shortAccountNumber: '',
    id: '',
    isActive: false
  };
  isFullnameValid = true;
  isAccountNumberValid = true;
  isBankValid = true;
  selectedObject: any;
  isUpdate = false;
  constructor(
    private globalService: GlobalService,
    public modalCtrl: ModalController,
    private navParams: NavParams,
  ) {
    this.data.accountNumber = this.navParams.data.accountNumber;
    this.data.bankId = this.navParams.data.bankId;
    this.data.fullname = this.navParams.data.fullname;
    this.data.id = this.navParams.data.id;
    this.isUpdate = this.data.id ? true : false;
    this.data.isActive = this.navParams.data.isActive;
   }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.globalService.getGlobal('BANK').subscribe(res => {
      this.banks = res;
      if (this.data.bankId !== '') {
        this.selectedObject = res.filter( bank => bank.id === this.data.bankId)[0];
        console.log(this.selectedObject);
      }
    }, (err) => {
    });
  }

  onBackFunc() {
    this.modalCtrl.dismiss();
  }
  onSaveChangeClick() {
    if (this.validate()) {
      this.data.shortAccountNumber = '******' + this.data.accountNumber.slice(this.data.accountNumber.length - 4);
      this.data.bankId = this.selectedObject.id;
      this.data.bankName = this.selectedObject.name;
      this.data.shortName = this.selectedObject.description;
      this.modalCtrl.dismiss(this.data);
    }
  }

  validate(): boolean {
    this.isFullnameValid = true;
    this.isAccountNumberValid = true;
    this.isBankValid = true;
    if (!this.data.fullname || this.data.fullname.length === 0) {
      this.isFullnameValid = false;
    }
    if (!this.data.accountNumber || this.data.accountNumber.length === 0) {
      this.isAccountNumberValid = false;
    }
    if (!this.selectedObject || !this.selectedObject.id || this.selectedObject.id.length === 0) {
      this.isBankValid = false;
    }
    return this.isFullnameValid && this.isAccountNumberValid && this.isBankValid;
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  ngOnChanges(changes: { [propKey: string]: any }) {

  }
}
