import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'statusToColor'

}) export class StatusToColor implements PipeTransform {

    transform(input: any): string {
        switch (input) {
            case 'Member':
                return 'success';
            case 'Activated':
                return 'success';
            case 'Unverified':
                return 'primary';
            case 'Suspended':
                return 'danger';
            case 'Rejected':
                return 'danger';
            case 'Waiting for approval':
                return 'primary';
            case 'Generated':
                return 'tertiary';
            case 'Successful':
                return 'success';
            case 'Approved':
                return 'success';
            case 'Failed':
                return 'danger';
            case 'Completed':
                return 'success';
            case 'Ready for delivery':
                return 'primary';
            case 'Ready for pickup':
                return 'primary';
            case 'Shipped':
                return 'tertiary';
        }
        return '';
    }
}