import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class User1Service {
  constructor(private apiService: ApiService) { }

  register(model: any): Observable<any> {
    return this.apiService.post('/user/register', model);
  }
  confirmEmail(model: any): Observable<any> {
    return this.apiService.post('/user/confirm/email', model);
  }
  changePassword(model: any): Observable<any> {
    return this.apiService.post('/user/change/password', model);
  }
  updateProfile(model: any): Observable<any> {
    return this.apiService.post('/user/update/profile', model);
  }
  userProfile() {
    return this.apiService.get('/user/profile');
  }
  userKyc() {
    return this.apiService.get('/user/kyc');
  }
  upgradeKyc(model: any): Observable<any> {
    return this.apiService.post('/user/kyc/upgrade', model);
  }
  getCurrentKyc() {
    return this.apiService.get('/user/kyc/tier/current');
  }

  getKycDetail(tierID) {
    return this.apiService.get('/user/kyc/detail/' + tierID);
  }
  userInfo() {
    return this.apiService.get('/user/userInfo');
  }
  getUserInfo(userId: string) {
    return this.apiService.get('/user/userInfo/' + userId);
  }
  forgotPassword(model: any): Observable<any> {
    return this.apiService.post('/user/password/forgot', model);
  }
  resetPassword(model: any): Observable<any> {
    return this.apiService.post('/user/reset/password', model);
  }

  getMyKyc() {
    return this.apiService.get('/user/mykyc');
  }

  checkUser(ic: string, email: string) {
    return this.apiService.get('/user/check?ic=' + ic + '&email=' + email);
  }

  checkIntroducer(code: string) {
    return this.apiService.get('/user/refcode/check/' + code);
  }

  registerAsk(model: any) {
    return this.apiService.post('/user/register/ask/account', model);
  }

  checkAskAccount() {
    return this.apiService.get('/user/check/ask');
  }

  registerFcm(model: any) {
    return this.apiService.post('/user/register/fcm', model);
  }
  getTenantName(tenantId: string) {
    return this.apiService.get('/user/getTenantName/' + tenantId);
  }

  getAskBalance() {
    return this.apiService.get('/user/ask/balance');
  }

  getReferral() {
    return this.apiService.get('/user/referral');
  }
  getPromotions() {
    return this.apiService.get('/user/promotions');
  }
  getPaymentResult(billcode: string) {
    return this.apiService.get('/payment/check/' + billcode);
  }
  getPaymentHistory(billcode: string) {
    return this.apiService.get('/payment/get/' + billcode);
  }
  updatePaymentStatus(billCode: string, statusId: number) {
    return this.apiService.post(`/payment/updateStatus/${billCode}/${statusId}`)
  }
  getNotifications() {
    return this.apiService.get('/user/notifications');
  }
  markReadNotification(id: string) {
    return this.apiService.post('/user/notification/read/' + id);
  }
  logout() {
    return this.apiService.post('/user/logout');
  }
  getServiceNotice() {
    return this.apiService.get('/user/service/notice');
  }
  getUserBanks() {
    return this.apiService.get('/user/bank/list');
  }
  addUserBank(model: any) {
    return this.apiService.post('/user/bank/add', model);
  }
  getAskSeryBalance() {
    return this.apiService.get('/user/ask/sery/balance');
  }
  checkExistAsk() {
    return this.apiService.get('/user/exist/ask');
  }
}
