import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthService } from './services/api/auth.service';
import { UserGuard } from './services/user.guard';
import { AuthGuard } from './shared/guards/auth.guard';
const routes: Routes = [

  {
    path: 'login',
    loadChildren: () => import('./ui-pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./ui-pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./ui-pages/main/main.module').then( m => m.MainPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'sery',
    loadChildren: () => import('./ui-pages/sery/sery.module').then( m => m.SeryPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./ui-pages/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'payment-result',
    loadChildren: () => import('./ui-pages/main/payment-result/payment-result.module').then( m => m.PaymentResultPageModule)
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./ui-pages/maintenance/maintenance.module').then( m => m.MaintenancePageModule)
  },  {
    path: 'pdpa-term-condition',
    loadChildren: () => import('./ui-pages/pdpa-term-condition/pdpa-term-condition.module').then( m => m.PdpaTermConditionPageModule)
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () => import('./ui-pages/terms-and-conditions/terms-and-conditions.module').then( m => m.TermsAndConditionsPageModule)
  },



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: false })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
