import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TravelImageViewPage } from 'src/app/ui-layouts/travel/travel-image-view/travel-image-view.page';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-register-user-documents',
  templateUrl: './register-user-documents.component.html',
  styleUrls: ['./register-user-documents.component.scss'],
})
export class RegisterUserDocumentsComponent implements OnInit, OnChanges {

  filePreviewIc: any;
  filePreviewSelfie: any;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onRegister = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onBack = new EventEmitter();
  @Input() type: string;
  readonly = false;
  @Input() data: any;
  profile = {
    selfiePhoto: '/assets/upload-image-plus.png',
    icPhoto: '/assets/upload-image-plus.png',
    agreedPdpa: false
  };
  icSizeError = false;
  selfSizeError = false;
  isAgree = false;
  constructor(
    private modalController: ModalController,
    private iab: InAppBrowser,
  ) { }

  ngOnInit() {
  }

  showPreviewSelfie(event) {
    const data = (event.target as HTMLInputElement).files;
    const element = data[0];
    const reader = new FileReader();
    reader.readAsDataURL(element);
    reader.onload = () => {
        this.selfSizeError =  this.calculateImageSize(reader.result) > 10;
        if (!this.selfSizeError) {
          this.filePreviewSelfie = reader.result;
          this.profile.selfiePhoto = this.filePreviewSelfie;
        }
    };
  }

  showPreviewIc(event) {
    const data = (event.target as HTMLInputElement).files;
    const element = data[0];
    const reader = new FileReader();
    reader.readAsDataURL(element);
    reader.onload = () => {
        this.icSizeError =  this.calculateImageSize(reader.result) > 10;
        if (!this.icSizeError) {
          this.filePreviewIc = reader.result;
          this.profile.icPhoto = this.filePreviewIc;
        }
    };
  }

  onBackClick() {
    this.onBack.emit();
  }

  onRegisterClick() {
    // if valid
    if (this.isAgree) {
      this.profile.agreedPdpa = true;
      this.onRegister.emit(this.profile);
    }
  }

  ngOnChanges(changes: { [propKey: string]: any }) {
    if (changes['data'] != null) {
      this.data = changes['data'].currentValue;
      if (this.data != null && this.data.length > 0) {
        this.data.forEach(element => {
          if (element.dataType === 'IMAGE') {
            if (element.position === 1) {
              this.profile.icPhoto = element.data;
            } else {
              this.profile.selfiePhoto = element.data;
            }
          }
        });
      }
    }

    if (changes.type != null) {
      if (this.type === 'SIGNUP') {
        this.readonly = false;
      } else {
        this.readonly = true;
      }
    }
  }

  calculateImageSize(base64String) {
    let padding;
    let inBytes;
    let base64StringLength;
    if (base64String.endsWith('==')) { padding = 2; } else if (base64String.endsWith('=')) { padding = 1; } else { padding = 0; }

    base64StringLength = base64String.length;
    console.log(base64StringLength);
    inBytes = (base64StringLength / 4) * 3 - padding;
    console.log(inBytes);
    return (inBytes / 1000) / 1024;
  }

  openImageViewer(image) {
    const lst = [image];
    this.modalController.create({
      component: TravelImageViewPage,
      cssClass: 'fullscreen-modal',
      componentProps: {
        data: lst,
        index: image
      }
    }).then(modal => {
      modal.present();
    });
  }

  viewPdpa() {
    const browser = this.iab.create(`${environment.webUrl}${'pdpa-term-condition'}`, '_system');
    browser.show();
  }
}
