export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyB-Y08RRAdcJVX8EcHjpkOEC0GY4VrplPo",
    authDomain: "diya-2bd01.firebaseapp.com",
    projectId: "diya-2bd01",
    storageBucket: "diya-2bd01.appspot.com",
    messagingSenderId: "675524445273",
    appId: "1:675524445273:web:ef26e80cf1b16c1e1cdd48",
    measurementId: "G-4P95KRX4K3"
  },
  apiEndpoint: 'https://apisb.yapeim.my/api/v1',
  webUrl: 'https://diyasb.yapeim.my/',
  version: '1.6.6'
};
/*
https://api.yapeim.my/api/v1
http://113.23.181.173:8088/api/v1
http://localhost:57199/api/v1
https://apisb.yapeim.my/api/v1
*/
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
