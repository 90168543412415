import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HideHeaderDirective } from './hide-header.directive';
import { RegisterConfirmComponent } from '../components/register-confirm/register-confirm.component';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RegisterUserDetailsComponent } from '../components/register-user-details/register-user-details.component';
import { RegisterUserDocumentsComponent } from '../components/register-user-documents/register-user-documents.component';
import { ResetPasswordComponent } from '../components/reset-password/reset-password.component';
import { UserPersonalDetailComponent } from '../components/user-personal-detail/user-personal-detail.component';
import { TravelImageViewPage } from '../ui-layouts/travel/travel-image-view/travel-image-view.page';
import { SystemNoticeComponent } from '../components/system-notice/system-notice.component';
import { UserSummaryComponent } from '../components/user-summary/user-summary.component';
import { PaymentSummaryComponent } from '../components/payment-summary/payment-summary.component';
import { SupportComponent } from '../components/support/support.component';
import { BankDetailComponent } from '../components/bank-detail/bank-detail.component';
import { PayoutRequestComponent } from '../components/payout-request/payout-request.component';
import { PayoutDetailComponent } from '../components/payout-detail/payout-detail.component';
import { TransactionTotalComponent } from '../components/transaction-total/transaction-total.component';
import { ChartsModule } from 'ng2-charts';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestore } from '@angular/fire/firestore';
import { AddressDetailComponent } from '../components/address-detail/address-detail.component';
import { WithdrawDigitalDetailComponent } from '../components/withdraw-digital-detail/withdraw-digital-detail.component';
@NgModule({
  declarations: [
    HideHeaderDirective,
    RegisterConfirmComponent,
    RegisterUserDetailsComponent,
    RegisterUserDocumentsComponent,
    ResetPasswordComponent,
    UserPersonalDetailComponent,
    TravelImageViewPage,
    SystemNoticeComponent,
    UserSummaryComponent,
    PaymentSummaryComponent,
    SupportComponent,
    BankDetailComponent,
    PayoutRequestComponent,
    PayoutDetailComponent,
    TransactionTotalComponent,
    AddressDetailComponent,
    WithdrawDigitalDetailComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ChartsModule
  ],
  exports: [
    HideHeaderDirective,
    RegisterConfirmComponent,
    RegisterUserDetailsComponent,
    RegisterUserDocumentsComponent,
    ResetPasswordComponent,
    UserPersonalDetailComponent,
    TravelImageViewPage,
    SystemNoticeComponent,
    UserSummaryComponent,
    PaymentSummaryComponent,
    SupportComponent,
    BankDetailComponent,
    PayoutRequestComponent,
    PayoutDetailComponent,
    TransactionTotalComponent,
    AddressDetailComponent,
    WithdrawDigitalDetailComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [TravelImageViewPage, SystemNoticeComponent, SupportComponent, BankDetailComponent,
     PayoutRequestComponent, PayoutDetailComponent, TransactionTotalComponent, AddressDetailComponent, WithdrawDigitalDetailComponent],
})
export class SharedModule { }
