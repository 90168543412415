import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import { AuthService } from 'src/app/services/api/auth.service';
import { WithdrawalService } from 'src/app/services/api/withdrawal.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-withdraw-digital-detail',
  templateUrl: './withdraw-digital-detail.component.html',
  styleUrls: ['./withdraw-digital-detail.component.scss'],
})
export class WithdrawDigitalDetailComponent implements OnInit {

  data = {
    amount: 0,
    status: '',
    createdOn: '',
    remark: '',
    id: '',
    receiveType: '',
    goldPurity: '',
    shippingDetail: {
      address1: '',
      address2: '',
      fullname: '',
      mobile: '',
      state: '',
      city: '',
      postalCode: ''
    },
    transactionNo: '',
    approvedDate: '',
    readyForDeliveryDate: '',
    deliveredDate: '',
    completedDate: '',
    rejectedDate: '',
    trackingNo: '',
    estimatedDays: 0,
    courier: '',
  };
  model = {
    status: '',
    id: '',
    remark: '',
    trackingNo: '',
    estimatedDays: 0,
    courier: ''
  };
  id: any;
  constructor(
    private modalCtrl: ModalController,
    public alertCtrl: AlertController,
    private withdrawalService: WithdrawalService,
    private toastCtrl: ToastService,
    private navParams: NavParams,
    private authService: AuthService
  ) { }

  isAdmin = false;
  isTrackingNoValid = true;
  isEstimatedDayValid = true;
  private estimatedDayReg = /^[0-9]*$/;
  ngOnInit() {
    const user = this.authService.currentUserValue;
    if (user != null && (user.roles.includes('G$SUPPER_ADMIN') || user.roles.includes('G$ADMIN'))) {
      this.isAdmin = true;
    }
    this.id = this.navParams.data.id;
    if (this.isAdmin) {
      this.getDataByAdmin();
    } else {
      this.getData();
    }
  }

  getData() {
    this.withdrawalService.getMyWithdrawalDetail(this.id).subscribe(res => {
      this.data = res;
    },
    (err) => {
      this.toastCtrl.error(err);
    }
    );
  }

  getDataByAdmin() {
    this.withdrawalService.getWithdrawalDetail(this.id).subscribe(res => {
      this.data = res;
    },
    (err) => {
      this.toastCtrl.error(err);
    }
    );
  }

  async confirmToSubmit(status) {

    let isValid = true;
    let confirmMessage = 'Are you sure you want to perform this transaction?';
    if (status === 'Approved') {
      // tslint:disable-next-line:max-line-length
      confirmMessage = `<p>Are you sure you want to proceed. Please confirm the following:</p><p> - Physical gold stock availability.</p><p> - Supported shipping address.</p>`;
    } else if (status === 'Shipped' || status === 'Ready for delivery') {
      isValid = this.validate();
    }

    if (isValid) {
      const alert = await this.alertCtrl.create({
        header: 'Confirmation',
        message: confirmMessage,
        subHeader: '',
        cssClass: 'info-dialog',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
            }
          },
          {
            text: 'Ok',
            handler: () => {
              this.onSubmit(status);
            }
          }
        ]
      });
      await alert.present();
    }
  }

  onSubmit(status) {
    this.model.id = this.id;
    this.model.status = status;
    this.model.remark = this.data.remark;
    this.model.trackingNo = this.data.trackingNo;
    if (this.data.estimatedDays) {
      this.model.estimatedDays =  this.data.estimatedDays;
    } else {
      this.model.estimatedDays = 0;
    }
    this.model.courier = this.data.courier;
    this.modalCtrl.dismiss(this.model);
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  validate() {
    this.isTrackingNoValid = true;
    if (!this.data.trackingNo || this.data.trackingNo.length === 0) {
      this.isTrackingNoValid = false;
    }

    return this.isTrackingNoValid;
  }

}
